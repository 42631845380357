import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User } from '../_models/user';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public apiUrl = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string
    , private data: DataService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.apiUrl = baseUrl + 'api/Account/';
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setUser(user: User) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }
  login(email: string, password: string) {
    return this.http.post<any>(this.apiUrl + 'login', { email, password })
      .pipe(map(result => {
        if (result.isSuccess) {
          sessionStorage.setItem('currentUser', JSON.stringify(result.data));
          this.currentUserSubject.next(result.data);
          this.data.CurrentUserChanges(result.data);
        }
        return result;
      }));
  }
  refresh() {
    return this.http.get<any>(this.apiUrl + 'refresh')
      .pipe(map(result => {
        if (result.isSuccess) {
          sessionStorage.setItem('currentUser', JSON.stringify(result.data));
          this.currentUserSubject.next(result.data);
        }
        return result;
      }));
  }
  logout() {
    return this.http.post<any>(this.apiUrl + 'logout', {})
      .pipe(map(user => {
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        return user;
      }));
  }

  forgot(email: string) {
    return this.http.post<any>(this.apiUrl + 'forgotpassword', { email }, { withCredentials: false })
      .pipe(map(user => {
        return user;
      }));
  }
  contact(model: any) {
    return this.http.post<any>(this.apiUrl + 'contact', { model }, { withCredentials: false })
      .pipe(map(user => {
        return user;
      }));
  }
  resetPassword(model: any) {
    return this.http.post<any>(this.apiUrl + 'setupPassword', model, { withCredentials: false })
      .pipe(map(user => {
        return user;
      }));
  }
  signup(model: any) {
    return this.http.post(this.apiUrl + 'signup', model);
  }  
  getlevelslist() {
    return this.http.get<any>(this.apiUrl + 'fillLevels').pipe(map(data => {
      if (data) {
        return data;
      } else {
        return null;
      }
    }));
  }
  VerifyAccount(token: any) {
    return this.http.get(this.apiUrl + "verifySetupPasswordLink?link=" + token)
      .pipe(map(respo => {
        return respo;
      }));
  }

  GetDecryptUserMailId(emailid) {
    return this.http.get<any>(this.apiUrl + 'GetDecryptUserEmailId?Emailid=' + emailid, {})
      .pipe(map(result => {
        return result;
      }));
  }
  getGoogleCaptchaKey() {
    return this.http.get<any>(this.apiUrl + 'GetGoogleCaptchaSiteKey', {})
      .pipe(map(result => {
        return result;
      }));
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms"; import { AlertifyService } from "../../../_services/alertify.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { MustMatch } from '../../../_helper/_helper.Match';
declare var $;
import Swal from 'sweetalert2';
import { DataService } from "../../../_services/data.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  data: any;
  signupForm: FormGroup;
  model: any = {};
  returnUrl: string;
  submitted: boolean = false;
  modelType = 'danger';
  modelMessage = '';
  IsRememberMe: boolean = false;
  IsLoader: boolean = false;
  IsValidCaptch: boolean = false;
  size = '200px'; LevelsList: any;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private alertify: AlertifyService,
    private avRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService, private activeRoute: ActivatedRoute
    , private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      Title: ["", [Validators.required]],
      Email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$")]],
      Name: ['', [Validators.required, Validators.maxLength(50)]],
      Mobile: ['', [Validators.required, Validators.maxLength(12)]],
      LevelId: ["", [Validators.required]],
      Password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15)
        ],
      ],
      ConfirmPassword: ["", [Validators.required]],
    },
      {
        validators: MustMatch("Password", "ConfirmPassword"),
      });
    this.modelType = '';
    this.modelMessage = '';
    this.GetLevelsList();
  }

  ngOnDestroy(): void {
  }
  get f() {
    return this.signupForm.controls;
  }
  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
  GetLevelsList() {
    this.authService.getlevelslist().subscribe(data => {
      if (data.length > 0) {
        this.LevelsList = data;
      }
    });
  }
  onLoginSubmit() {
    this.IsLoader = true;
    this.submitted = true;
    if (this.signupForm.invalid) {
      this.IsLoader = false;
      return;
    }
    this.submitted = false;
    this.SpinnerService.show();
    this.authService.signup(this.signupForm.value).subscribe(data => {
      const o = Object(data);
      this.router.navigate(['']);
      this.alertify.success(o.message.toString()); this.SpinnerService.hide();
    }, (err) => {
      this.IsLoader = false;
      this.submitted = false;
      this.modelMessage = err.error.message;
      this.modelType = 'danger';
      this.alertify.error(err.error.message);
      this.SpinnerService.hide();
    });
  }
  hideMessage() {
    this.modelMessage = '';
    this.modelType = '';
  }
}

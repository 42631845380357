import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({ providedIn: "root" })
export class DataService {
  private CurrentUsers: BehaviorSubject<any> = new BehaviorSubject([]);
  CurrentUsersObservable: Observable<any> = this.CurrentUsers.asObservable();

  private BreadcrumbData: BehaviorSubject<any> = new BehaviorSubject([]);
  BreadcrumbDataObservable: Observable<any> = this.BreadcrumbData.asObservable();

  private IsVideoApiExist: BehaviorSubject<any> = new BehaviorSubject([]);
  IsVideoApiExistObservable: Observable<any> = this.IsVideoApiExist.asObservable();

  private LiveClassUrl: BehaviorSubject<any> = new BehaviorSubject([]);
  LiveClassUrlObservable: Observable<any> = this.LiveClassUrl.asObservable();

  private ImageUrl: BehaviorSubject<any> = new BehaviorSubject([]);
  ImageUrlObservable: Observable<any> = this.ImageUrl.asObservable();

  private CmsPages: BehaviorSubject<any> = new BehaviorSubject([]);
  CmsPagesObservable: Observable<any> = this.CmsPages.asObservable();

  constructor() { }
 
  CurrentUserChanges(userData) {
    this.CurrentUsers.next(userData);
  }

  BreadcrumbChange(value) {
    this.BreadcrumbData.next(value);
  }

  IsVideoApiExistChange(value) {
    this.IsVideoApiExist.next(value);
  }

  LiveClassUrlChange(value) {
    this.LiveClassUrl.next(value);
  }

  ImageUrlChange(value) {
    this.ImageUrl.next(value);
  }

  CmsPagesChange(value) {
    this.CmsPages.next(value);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./modules/website/guest.module').then(m => m.GuestModule) },
  { path: 'account', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'student', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule) }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, HostListener, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';
import { DataService } from './_services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AlertifyService } from './_services/alertify.service';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'ClientApp';
  public forecasts: any[];
  currentUser: User;
  notificationMessage: any;
  public apiUrl = '';
  constructor(private titleService: Title, private metaService: Meta
    , private _authService: AuthenticationService, private router: Router
    , private data: DataService,
    private http: HttpClient, private alertify: AlertifyService,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.apiUrl = baseUrl + 'api/Home/';

    this._authService.currentUser.subscribe((user) => {
      this.currentUser = user;
      if (localStorage.getItem("profileImage") != null && localStorage.getItem("profileImage") != undefined) {
        this.data.ImageUrlChange(localStorage.getItem("profileImage"));
      }
      else {
        if (this.currentUser != null) {
          this.data.ImageUrlChange(this.currentUser.profilePic);
          localStorage.setItem("profileImage", this.currentUser.profilePic);
        }
      }
    });
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  //@HostListener('window:beforeunload', ['$event'])
  //beforeunloadHandler(event): void {
  //  if (this.currentUser != null) {
  //    this._authService.logout().subscribe(data => {
  //      if (!this._authService.currentUserValue) {
  //        $('body').removeClass('nav-icon');
  //        this.alertify.success("You have been successfully logged out!")
  //        this.router.navigate(['']);
  //      }
  //    })
  //  }
  //  //event = window.event || event;
  //  //if (event.clientX || 0 && event.clientY < 0) {
  //  //  alert("Window closed");
  //  //}
  //  //else {
  //  //  alert("Window refreshed");
  //  //}
  //}
}
